// src/components/About.tsx

import { Place } from '@mui/icons-material';
import Button from '@mui/material/Button';
import React from 'react';
import { useBulletins } from '../services';
import { months } from '../utils';

const Intro = () => {
  const currentDate = new Date();

  const id = `${months[currentDate.getMonth()]}-${currentDate
    .getFullYear()
    .toString()}`;
  const { bulletins } = useBulletins(id);

  return (
    <div className="intro">
      <div>This is NMZ Lakeland</div>
      <div>Welcome Home</div>
      <div>
        Let us discover all the ways we can celebrate Christ and get closer to
        the Lord, together
      </div>
      <div>Worship with us every Sunday at 11AM</div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '2rem',
          fontSize: '1rem',
          fontWeight: 'bold',
        }}
      >
        <Place color="primary" style={{ color: 'white' }} />
        <a
          href="https://www.google.com/maps/place/1321+N+Webster+Ave,+Lakeland,+FL+33805"
          target="_blank"
          rel="noreferrer"
        >
          1321 N Webster Ave, Lakeland, FL 33805
        </a>
      </div>
      <Button
        variant="contained"
        href="/services"
        color="primary"
        style={{ backgroundColor: 'rgb(233, 32, 79)' }}
      >
        Learn More
      </Button>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {bulletins.length > 0 && (
          <Button
            variant="outlined"
            // variant="contained"
            href={bulletins[0].link}
            target="_blank"
            color="primary"
            // style={{ backgroundColor: 'rgb(233, 32, 79)' }}
            sx={{ mt: 2, borderColor: '#fff' }}
          >
            View {bulletins[0].month} Bulletin
          </Button>
        )}
        <Button
          //variant="outlined"
          variant="contained"
          href="https://firebasestorage.googleapis.com/v0/b/nmzlakeland-a8568.appspot.com/o/files%2FBusiness%20Directory%20FINAL.pdf?alt=media&token=a3ed7da0-7877-45c0-8b8f-4a75c5428fbf"
          target="_blank"
          color="secondary"
          // style={{ backgroundColor: 'rgb(233, 32, 79)' }}
          sx={{ mt: 2, borderColor: '#fff', ml: 3 }}
        >
          NMZ Business Directory
        </Button>
      </div>
    </div>
  );
};

export default Intro;
